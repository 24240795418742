import React, {FC, useState} from 'react';
import axios from 'axios';
import {Define} from "../define/define";
import './login.css';

const Login: FC = () => {
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');
    const [isLogin, setIsLogin] = useState(false);

    const [todaynum, setTodaynum] = useState('0');
    const [registernum, setRegisternum] = useState('0');
    const [loginnum, setLoginnum] = useState('0');
    const [loginsum, setLoginsum] = useState('0');
    const [tonsum, setTonsum] = useState('0');
    const [usdtsum, setUsdtsum] = useState('0');
    const [notsum, setNotsum] = useState('0');

    const [sendAccount, setSendAccount] = useState('');
    const [getaccountconsume, setAccountConsume] = useState('');

    const [shopid, setShopId] = useState(1);
    const [coinprice, setCoinPrice] = useState(0);
    const [tonprice, setTonPrice] = useState(0);
    const [usdtprice, setUSDTPrice] = useState(0);
    const [notprice, setNotPrice] = useState(0);
    const [priceResult, setPriceResult] = useState("");

    const onAccountChange = (event: any) => {
        setAccount(event.target.value);
    }

    const onPasswordChange = (event: any) => {
        setPassword(event.target.value);
    }

    const onSendAccountChange = (event: any) => {
        setSendAccount(event.target.value);
    }

    const onShopIdChange = (event: any) => {
        let numIntCorrect = event.target.value;
        if (numIntCorrect === "") {
            numIntCorrect = 0;
        }
        setShopId(numIntCorrect);
    }
    const onCoinPriceChange = (event: any) => {
        let numIntCorrect = event.target.value;
        if (numIntCorrect === "") {
            numIntCorrect = 0;
        }
        setCoinPrice(numIntCorrect);
    }
    const onTonPriceChange = (event: any) => {
        let numIntCorrect = event.target.value;
        if (numIntCorrect === "") {
            numIntCorrect = 0;
        }
        setTonPrice(numIntCorrect);
    }
    const onUSDTPriceChange = (event: any) => {
        let numIntCorrect = event.target.value;
        if (numIntCorrect === "") {
            numIntCorrect = 0;
        }
        setUSDTPrice(numIntCorrect);
    }
    const onNotPriceChange = (event: any) => {
        let numIntCorrect = event.target.value;
        if (numIntCorrect === "") {
            numIntCorrect = 0;
        }
        setNotPrice(numIntCorrect);
    }

    const onLoginClick = async () => {
        const loginData = {
            'account': account,
            'password': password,
        };
        const loginDataStr = JSON.stringify(loginData);
        const data = {
            'action': 'login',
            'data': loginDataStr,
        };
        JSON.stringify(data, null, 2)
        const body = JSON.stringify(data);
        axios.post(Define.Url, body).then((response) => {
            console.log(response.data);
            if (response.data['success'].toString() == "1") {
                setIsLogin(true);
                //登录完成 获取后台基础信息
                const data = {
                    'action': 'getinfo',
                    'data': '',
                };
                const getinfo = JSON.stringify(data);
                axios.post(Define.Url, getinfo).then((res) => {
                    console.log(res.data);
                    setTodaynum(res.data['today_num']);
                    setRegisternum(res.data['register_num'])
                    setLoginnum(res.data['login_num'])
                    setLoginsum(res.data['login_sum'])
                    setTonsum(res.data['summoney']['ton_sum'])
                    setUsdtsum(res.data['summoney']['usdt_sum'])
                    setNotsum(res.data['summoney']['not_sum'])

                }).catch(error => {
                    console.log(error);
                });
            } else {
                setIsLogin(false);
                setResult(response.data['msg'].toString());
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const onRefreshClick = async () => {
        const data = {
            'action': 'getinfo',
            'data': '',
        };
        const getinfo = JSON.stringify(data);
        axios.post(Define.Url, getinfo).then((res) => {
            console.log(res.data);

            setTodaynum(res.data['today_num'])
            setRegisternum(res.data['register_num'])
            setLoginnum(res.data['login_num'])
            setLoginsum(res.data['login_sum'])
            setTonsum(res.data['summoney']['ton_sum'])
            setUsdtsum(res.data['summoney']['usdt_sum'])
            setNotsum(res.data['summoney']['not_sum'])

        }).catch(error => {
            console.log(error);
        });
    }

    const onGetAccountInfoClick = async () => {
        const loginData = {
            'account': sendAccount,
        };
        const loginDataStr = JSON.stringify(loginData);
        const data = {
            'action': 'getaccountconsume',
            'data': loginDataStr,
        };
        const body = JSON.stringify(data);
        axios.post(Define.Url, body).then((response) => {
            console.log(response.data);
            setAccountConsume(JSON.stringify(response.data['data']))
        }).catch(error => {
            console.log(error);
        });
    }

    const onGetAccountFriendClick = async () => {
        const loginData = {
            'account': sendAccount,
        };
        const loginDataStr = JSON.stringify(loginData);
        const data = {
            'action': 'getfriend',
            'data': loginDataStr,
        };
        const body = JSON.stringify(data);
        axios.post(Define.Url, body).then((response) => {
            console.log(response);
            setAccountConsume(JSON.stringify(response.data))
        }).catch(error => {
            console.log(error);
        });
    }

    const onGetUserInfoClick = async () => {
        const loginData = {
            'account': sendAccount,
        };
        const loginDataStr = JSON.stringify(loginData);
        const data = {
            'action': 'getuserinfo',
            'data': loginDataStr,
        };
        const body = JSON.stringify(data);
        axios.post(Define.Url, body).then((response) => {
            console.log(response);
            setAccountConsume(JSON.stringify(response.data))
        }).catch(error => {
            console.log(error);
        });
    }

    const onGetShopPriceClick = async () => {
        const data = {
            'action': 'getshopprice',
            'data': '',
        };
        const body = JSON.stringify(data);
        axios.post(Define.Url, body).then((response) => {
            console.log(response);
            setAccountConsume(JSON.stringify(response.data['data']))
        }).catch(error => {
            console.log(error);
        });
    }

    const onChangeShopPriceClick = async () => {
        const loginData = {
            'shop_id': parseInt(String(shopid)),
            'coin_price': parseFloat(String(coinprice)),
            'ton_price': parseFloat(String(tonprice)),
            'usdt_price': parseFloat(String(usdtprice)),
            'not_price': parseFloat(String(notprice)),
        };
        const loginDataStr = JSON.stringify(loginData);
        console.log(loginDataStr);
        const data = {
            'action': 'setshopprice',
            'data': loginDataStr,
        };
        const body = JSON.stringify(data);
        axios.post(Define.Url, body).then((response) => {
            console.log(response);
            setPriceResult(JSON.stringify(response.data));
            onGetShopPriceClick();
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div>
            {
                isLogin
                    ?
                    <div className="content">
                        <div>
                            <button type="button" onClick={onRefreshClick}>刷新</button>
                        </div>
                        <div className='todayRegisterNum'>今日註冊人數:{todaynum}</div>
                        <div className='allRegisterNum'>總註冊人數:{registernum}</div>
                        <div className='todayLoginNum'>今日登錄人數:{loginnum}</div>
                        <div className='allLoginNum'>總登錄人數:{loginsum}</div>
                        <div className='tonsum'>總TON:{tonsum}</div>
                        <div className='usdtsum'>總USDT:{usdtsum}</div>
                        <div className='notsum'>總NOT:{notsum}</div>

                        <div>
                            <input type="text" placeholder="账户" value={sendAccount} onChange={onSendAccountChange}/>
                            <button type="button" onClick={onGetUserInfoClick}>账号信息</button>
                            <button type="button" onClick={onGetAccountInfoClick}>账号的消费日志</button>
                            <button type="button" onClick={onGetAccountFriendClick}>账号的好友关系</button>
                            <button type="button" onClick={onGetShopPriceClick}>获取商品价格信息</button>
                            <div>{getaccountconsume}</div>
                        </div>

                        <div>修改商品价格</div>
                        <div>
                            商品配置ID:<input type="number" placeholder="商品配置ID" value={shopid}
                                              onChange={onShopIdChange}/>
                        </div>
                        <div>
                            商品积分价格:<input type="number" placeholder="商品积分价格" value={coinprice}
                                                onChange={onCoinPriceChange}/>
                        </div>
                        <div>
                            商品ton价格:<input type="number" placeholder="商品ton价格" value={tonprice}
                                               onChange={onTonPriceChange}/>
                        </div>
                        <div>
                            商品usdt价格:<input type="number" placeholder="商品usdt价格" value={usdtprice}
                                                onChange={onUSDTPriceChange}/>
                        </div>
                        <div>
                            商品not价格:<input type="number" placeholder="商品not价格" value={notprice}
                                               onChange={onNotPriceChange}/>
                        </div>
                        <div>
                            <button type="button" onClick={onChangeShopPriceClick}>修改商品价格</button>
                        </div>
                        <div>{priceResult}</div>
                    </div>
                    :
                    <div>
                        <h2>ToDaMoon</h2>
                        <form>
                            <div>
                                <input type="text" placeholder="用戶名" value={account} onChange={onAccountChange}/>
                            </div>
                            <div>
                                <input type="password" placeholder="密碼" value={password} onChange={onPasswordChange}/>
                            </div>
                            <div>
                                <div className='result'>{result}</div>
                            </div>
                            <button type="button" onClick={onLoginClick}>登錄</button>
                        </form>
                    </div>
            }
        </div>
    );
}

export default Login;